<template>
	<div class="innerCont">

		<img src="/sys_img/infos/pked_01.jpg">

		<ol>
			<li><strong>Text Filter</strong></li>
			<li><strong>Kategorien Wahl</strong></li>
			<li><strong>Neue Kategorie</strong> anlegen <br>Kategorien mit gleichen Name sind nicht möglich.</li>
			<li><strong>Kategorie löschen</strong> <br> die Kat. kann gelöscht werden wenn es keine PK (Pflanzenkatalog) Einträge mehr gibt.</li>
			<li><strong>Bild einfügen / ändern</strong> <br>auf das Bild klicken um den Bild Import zu starten</li>
			<li>PK Eintrag im Frontend <strong>sichtbar</strong> / <strong>unsichtbar</strong> machen.</li>
			<li><strong>Details eingeben</strong> <br>öffnet Editor um Text zu ändern</li>
			<li>PK <strong>Eintrag löschen</strong></li>
			<li><strong>neuen PK Eintrag</strong> anlegen <br>öffnet Editor um Text zu einzugeben <br>in einem zweiten Schritt kann ein bild hinzugefügt werden.</li>
		</ol>

		<div style="padding: 1rem 0"><hr></div>

		<img src="/sys_img/infos/pked_02.jpg">

		<ol>
			<li><strong>Bild wählen</strong> <br>Bild vom lokalen Computer importieren</li>
			<li><strong>Ausschnitt für Vorschaubild</strong> <br>Zoom mit Mausrad änder, Ausschnitt wählen per click and drag</li>
			<li><strong>Ausschnitt für Vollbild</strong></li>
			<li><strong>Vorschau</strong> <br>zeigt die Ergebnisse an, mit speichern bestätigen oder neue Vorschau wählen</li>
		</ol>

	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'pked',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style lang="scss" scoped>

.innerCont {
	margin-top: 2rem;

	& img {
		width: 80%;
	}
}

.infoList{
	display: flex;
	flex-direction: column;
	& div.item {
		display: flex;

		
		&:nth-child(odd) {
			background-color: #ddd;
			font-weight: bold;
			margin-top: 2rem;
		}

		& > div:nth-child(1){
			flex: 1;
			text-align: right;
			padding-right: 2rem;
		}
		& > div:nth-child(2){
			flex: 3;
			padding: 0 1rem;

			

		}

	}

}

.infoList li {
padding: 0.3rem 0px;
}

</style>
