<template>
	<div class="innerCont">

		<img src="/sys_img/infos/ted_01.jpg" width="708"><br><br>

		Zum Editieren Bereich selektieren oder auf Wort klicken.
		<ol>
			<li>Fettschrift</li>
			<li>Kursivschrift</li>
			<li>entfernt alle Formatierungen für den selektierten Bereich</li>

			</ol>

		<div style="padding: 1rem 0"><hr></div>

		<img src="/sys_img/infos/ted_02.jpg" width="708"><br><br>
				<ol>
			<li>Formate können auch mehrfach gewählt werden</li>
			<li>Eintrag gewählt, neuer Klick schaltet ihn wieder aus.</li>

			</ol>
		
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'ged',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style lang="scss" scoped>

.innerCont {
	margin-top: 2rem;

	& img {
	}
}

.infoList{
	display: flex;
	flex-direction: column;
	& div.item {
		display: flex;

		
		&:nth-child(odd) {
			background-color: #ddd;
			font-weight: bold;
			margin-top: 2rem;
		}

		& > div:nth-child(1){
			flex: 1;
			text-align: right;
			padding-right: 2rem;
		}
		& > div:nth-child(2){
			flex: 3;
			padding: 0 1rem;

			

		}

	}

}

.infoList li {
padding: 0.3rem 0px;
}

</style>
