<template>
	<div class="innerCont">

		<img src="/sys_img/infos/bed_01.jpg" width="428">

		<ol>
			<li><strong>Kategorie wählen</strong> <br> Bildwahl für Home, sowie der Gartengestaltung Seiten</li>
			<li><strong>Bild Import</strong> gleich wie Bild Import Pflanzenkatalog </li>
			<li>Bild im Frontend <strong>sichtbar</strong> / <strong>unsichtbar</strong> machen.</li>
			<li>
				<strong>Meta Daten eingeben</strong> <br>öffnet Text Editor für Title & Alt-Text <br>
				Title = Bildbeschreibung, wird bei "mouse over" und in der "light box" angezeigt. <br>
				alt-text = alternativer Text falls das Bild in einem Browser nicht angezeigt wird.
			</li>
			</ol>


	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'pked',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style lang="scss" scoped>

.innerCont {
	margin-top: 2rem;

	& img {
	}
}

.infoList{
	display: flex;
	flex-direction: column;
	& div.item {
		display: flex;

		
		&:nth-child(odd) {
			background-color: #ddd;
			font-weight: bold;
			margin-top: 2rem;
		}

		& > div:nth-child(1){
			flex: 1;
			text-align: right;
			padding-right: 2rem;
		}
		& > div:nth-child(2){
			flex: 3;
			padding: 0 1rem;

			

		}

	}

}

.infoList li {
padding: 0.3rem 0px;
}

</style>
