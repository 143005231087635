<template>
	<div class="container">

		<div style="margin: 2rem 0 1rem 0"><h2>Infos</h2></div>

		<div class="selection">
				<select v-model="selInfoLink" class="">
					<option value="hilfe">Hilfe</option>
					<option value="pked">Pflanzenkatalog Editor</option>
					<option value="bed">Bild Editor</option>
					<option value="ged">Galerie Editor(Schaugarten, Unsere Arbeiten)</option>
					<option value="ted">Text Editor</option>
				</select>
		


		</div>
			<component :is="selInfoLink"></component>

			<!-- component -->






</div> <!-- container end -->
</template>

<script>

	  import hilfe from '../views/infos/hilfe.vue';
	  import pked from '../views/infos/pked.vue';
	  import bed from '../views/infos/bed.vue';
	  import ged from '../views/infos/ged.vue';
	  import ted from '../views/infos/ted.vue';

	export default {
		name: 'Infos',
		components: {
			hilfe,
			pked,
			bed,
			ged,
			ted,
		},
		mounted(){
			

		},
		data: 
		function(){
			return {
				selInfoLink: 'hilfe',
			}
	}, // data end
	computed:{



	},
	methods: {



			} // methods end
		}


	</script>

	<style lang="scss" scoped>




	.container {
		// margin-top: 2rem !important;
			// border: 1px solid silver;

			// padding: 1rem;
			
	}

	.selection {


		& select {
			width: 23rem;
		}
		
	}

	.innerCont {
		// background-color: #EEE472 !important;
		height: 42rem;
		overflow: auto;
		
	}

	// 	.selection .innerCont .infoList {
	// 	display: flex;

	// 	& .item {
	// 		background-color: #EEE472;

	// 	}
	// }


</style>
