<template>
	<div class="innerCont">

		<img src="/sys_img/infos/ged_01.jpg" width="708">

		<ol>
			<li><strong>neues Bild</strong> <br>startet den Bild Import</li>
			<li><strong>Bild ändern</strong> <br>auf das Bild klicken um den Bild Import zu starten</li>
			<li>Bild im Frontend <strong>sichtbar</strong> / <strong>unsichtbar</strong> machen.</li>
					<li>
				<strong>Meta Daten eingeben</strong> <br>öffnet Text Editor für Title & Alt-Text <br>
				Title = Bildbeschreibung, wird bei "mouse over" und in der "light box" angezeigt. <br>
				alt-text = alternativer Text falls das Bild in einem Browser nicht angezeigt wird.
			</li>
			<li><strong>Bild löschen</strong></li>
			<li>
				<strong>Bild sortieren</strong>Bild per drag and drop auf eine neue Position ziehen <br>
				Sortierreihenfolge: links oben nach rechts unten.
			</li>
			</ol>

		<div style="padding: 1rem 0"><hr></div>

		<img src="/sys_img/infos/ged_02.jpg" width="708">

		<ol>
			<li><strong>Bild wählen</strong> <br>Bild vom lokalen Computer importieren</li>
			<li><strong>Ausschnitt für Vorschaubild</strong> <br>Zoom mit Mausrad änder, Ausschnitt wählen per click and drag</li>
			<li><strong>Ausschnitt für Vollbild</strong> <br>Griff für horizontale Änderung</li>
			<li><strong>Ausschnitt für Vollbild</strong> <br>Griff für vertikale Änderung</li>
			<li><strong>Vorschau</strong> <br>zeigt die Ergebnisse an, mit speichern bestätigen oder neue Vorschau wählen</li>

		</ol>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'ged',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style lang="scss" scoped>

.innerCont {
	margin-top: 2rem;

	& img {
	}
}

.infoList{
	display: flex;
	flex-direction: column;
	& div.item {
		display: flex;

		
		&:nth-child(odd) {
			background-color: #ddd;
			font-weight: bold;
			margin-top: 2rem;
		}

		& > div:nth-child(1){
			flex: 1;
			text-align: right;
			padding-right: 2rem;
		}
		& > div:nth-child(2){
			flex: 3;
			padding: 0 1rem;

			

		}

	}

}

.infoList li {
padding: 0.3rem 0px;
}

</style>
