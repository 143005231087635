<template>
	<div class="innerCont">
		<div class="infoList">

			<div class="item">
				<div></div>
				<div>Importierte Bilder werden im Frontend nicht angezeigt.</div>
			</div>

			<div class="item">
				<div></div>
				<div>
					Bilder werden beim ersten landen vom WebBrowser gespeichert <br>
					<strong> &lt;ctrl&gt;+&lt;shift&gt;+&lt;r&gt; </strong> löscht den Cache und lädt neu.
				</div>
			</div>

			<div class="item">
				<div></div>
				<div>Wie komme ich in das BackEnd</div>
			</div>

			<div class="item">
				<div></div>
				<div>
					<strong>viridarium.at/bearbeiten-<span style="color: var(--c12)">Tag</span></strong> eingeben<br>
					z.B. für den 1.1.2021: <strong>viridarium.at/bearbeiten-1</strong> <br>
					z.B. für den 31.1.2021: <strong>viridarium.at/bearbeiten-31</strong> <br>
				</div>
			</div>

			<hr>
			

			
		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'hilfe',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style lang="scss" scoped>

.innerCont {
}

.infoList{
	display: flex;
	flex-direction: column;
	 & div.item {
	display: flex;

		
		&:nth-child(odd) {
			background-color: #ddd;
			font-weight: bold;
			margin-top: 2rem;
		}

	& > div:nth-child(1){
		flex: 1;
		text-align: right;
		padding-right: 2rem;
	}
	& > div:nth-child(2){
		flex: 3;
		padding: 0 1rem;

			

	}
	 	
	 }

}

</style>
